import React from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import CurrentMonthWinner from "../../components/CurrentMonthWinner"
import WinningFilms from "../../components/WinningFilms"
import Seo from "../../components/Seo"
import { Helmet } from 'react-helmet'

const d = new Date();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const winnerCurrentMonth = months[d.getMonth() - 2]
const nomineeCurrentMonth = months[d.getMonth() - 1]

const pastWinners = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Past Winners</title>
        <link rel="canonical" href="https://filmchronicle.com/watch/past-winners" />
      </Helmet>
      <Seo title="Film Chronicle" description="Browse the hall of fame." image="../assets/images/film-chronicle-logo.svg" />
      <header className="hero">
        <StaticImage
          src="../../assets/images/Watch-Films__Past-Winners-Hero-New.jpeg"
          alt="flour and eggs"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container">
          <div className="hero-text">
            <h1>Past Winners</h1>
          </div>
        </div>
      </header>
      <div className="container-lg">
        <div className="section grid-cols-1 pb-50">
            <div>
              <h2 style={{fontSize: "30px"}}>{winnerCurrentMonth} {new Date().getFullYear()} Winner</h2>
              <p style={{fontSize: "20px"}}>Voted best film by the student judges</p>
            </div>
        </div>
      </div>
      <CurrentMonthWinner month={winnerCurrentMonth}/>
      <div className="container-lg">
        <div className="section grid-cols-1">
            <div>
              <h2 style={{fontSize: "30px"}}>{nomineeCurrentMonth} {new Date().getFullYear()} Nominees</h2>
              <p style={{fontSize: "20px"}}>Top upvoted films in {nomineeCurrentMonth}</p>
            </div>
        </div>
      </div>
      <WinningFilms/>
    </Layout>
  )
}

export default pastWinners
